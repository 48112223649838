@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body,
#root {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  padding: 0;
}
.recharts-wrapper *:focus {
  outline: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tab-option {
  padding: 10px 20px;
  cursor: pointer;
  text-align: center;
  transition: color 0.3s ease;
  flex: 0.5;
}

.tab-option.active {
  color: white;
  z-index: 1;
}

.indicator {
  height: 45px;
  background-color: #085454;
  position: absolute;
  bottom: 0;
  left: 0;
  transition:
    transform 0.3s ease,
    width 0.3s ease;
  border-radius: 8px;
  width: 50%;
}

.move-to-1 {
  transform: translateX(0%);
}

.move-to-2 {
  transform: translateX(100%);
}

.custom-grid {
  grid-template-columns: 1.2fr repeat(5, 1fr);
}
